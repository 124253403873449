@import '@/styles/common.scss';

.el-row {
  margin-bottom: 0px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.grid-content {
  border-radius: 4px;
  min-height: 34px;
  margin-top: 0px;
}

/deep/ .el-form--label-top .el-form-item__label {
  padding: 0;
}

/deep/ .el-form-item {
  margin-bottom: 0px;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
